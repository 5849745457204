import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {ActivatedRoute, Params} from '@angular/router';
import {OidcSecurityService} from 'angular-auth-oidc-client';
import {ClientIdAndNameTO} from 'api/entities';
import {EMPTY, Observable, throwError} from 'rxjs';
import {catchError, tap} from 'rxjs/operators';
import {apiVersion} from '../../../api-version.constant';
import {UserSessionService} from '../auth/user-session.service';
import {getCacheHeaders} from '../shared.service';
import {CurrentUser} from './avelon-user.interface';
import {Alcedo7User, updateUserName} from './avelon-user.service';

@Injectable({
  providedIn: 'root'
})
export class CurrentUserEntity {
  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    private oidcSecurityService: OidcSecurityService
  ) {}

  getCurrentUser(stateParams: Params): Observable<CurrentUser> {
    const clientId = stateParams ? +stateParams.clientId : +this.route.snapshot.paramMap.get('clientId');
    return this.getCurrentUserDefault(clientId).pipe(
      tap((user: CurrentUser) => {
        updateUserName(user);
        UserSessionService.setCurrentUser(user);
      }),
      catchError(err => {
        if (err.status === 403) {
          location.href = '/';
          return EMPTY;
        }
        return throwError(err);
      })
    );
  }

  getCurrentUserDefault(clientId?: number): Observable<CurrentUser> {
    return this.http.get<CurrentUser>(apiVersion + 'users/current' + (clientId ? '?clientId=' + clientId : '')).pipe(
      tap(user => {
        Alcedo7User.currentUser = user;
        Alcedo7User.selectedClient = user.currentClient;
        if (user.domain && !user.root && location.hostname !== 'localhost' && !Alcedo7User.isIoT && user.domain !== location.hostname) {
          // redirect user to correct hostname that corresponds to branding
          // ignore IoT app for now
          this.oidcSecurityService
            .logoffAndRevokeTokens(undefined, {
              urlHandler(url: string): any {
                location.href = 'https://' + user.domain;
              }
            })
            .subscribe();
        }
      })
    );
  }

  getClients(getCache?: boolean): Observable<ClientIdAndNameTO[]> {
    return this.http.get<ClientIdAndNameTO[]>(apiVersion + 'clients', getCacheHeaders(getCache));
  }
}
